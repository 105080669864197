import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Carousel} from "antd";
import logo from "../../assets/images/sp/sp-logo-13.svg"
import {DownloadOutlined, LinkOutlined} from "@ant-design/icons";
import bazzana1 from "../../assets/images/sp/13/bazzana-1.jpg";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Bazzana | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 13</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://bazzana.ch" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24, order: 1}} lg={{span: 14, order: 1}} className="text-justify mb-4">
                                <h6 className="text-primary text-uppercase fw-bold">Impresa di gessatura e di pittura
                                    dal 1971</h6>
                                <h1>Bazzana SA</h1>
                                <p>50 anni fa nasceva l’azienda Bazzana SA per volontà di Giuseppe Bazzana che con
                                    caparbietà e lungimiranza, gettava le basi di una società che, negli anni, ha
                                    conquistato una posizione di leadership nei settori di propria competenza:
                                    gessatura e pittura all’inizio, poi anche cartongessi, isolamenti termici, facciate
                                    ventilate e altre lavorazioni specialistiche nell’ambito delle finiture.</p>
                                <p>Spirito imprenditoriale, professionalità e ricerca costante della qualità, hanno
                                    permesso all’azienda di crescere fino a diventare una delle maggiori imprese del
                                    Canton Ticino e punto di riferimento per le altre aziende del ramo.</p>
                                <p>Nel corso degli anni l’azienda si è dotata di una moderna struttura manageriale in
                                    grado di soddisfare tutte le esigenze di un mercato difficile e competitivo.</p>
                                <p>La lunga esperienza accumulata, unita al continuo aggiornamento e allo sviluppo di
                                    nuovi prodotti e tecniche costruttive, hanno permesso a Bazzana SA di conquistarsi
                                    la stima e la fiducia di importanti architetti, imprenditori e fornitori. Il
                                    successo di questo percorso imprenditoriale iniziato tanti anni fa, è oggi lo
                                    stimolo per affrontare le nuove sfide con lo stesso entusiasmo e la stessa
                                    determinazione.</p>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 10, order: 2}} className="text-center">
                                <img src={bazzana1} alt="Bazzana"
                                     className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section>
                    <div className="container text-center">
                        <div className="youtubeContainer">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/weOjZ9sv2rY"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorPage
